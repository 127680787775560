.footer {
  @extend %white-text;
  @include box-shadow(0, 5px, 10px, rgba(0, 0, 0, .15));

  background-color: $dark-grey;
  font-size: .875em;
  padding: 16px 0;
  position: relative;
  width: 100%;

  span { display: inline-block; }

  > * { margin: 0 40px; }

  .ember-view { display: inline-block; }

  .icon-badge {
    margin: 0;

    a {
      font-size: 1em;
      height: 20px;
      line-height: 20px;
      margin: 0;
      width: 20px;
    }

    i { color: $accent-color-1; }

    @media screen and (max-width: 1050px) {
      margin: 0;
    }
  }

  .copy {
    position: absolute;
    right: 16px;
  }

  @media screen and (max-width: 432px) {
    span {
      display: block;
      text-align: center;
    }

    .copy {
      bottom: 0;
      position: relative;
      right: 0;
    }
  }
}
