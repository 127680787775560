// mixins
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($v, $b, $s, $c) {
  -webkit-box-shadow: $v $b $s $c;
  -moz-box-shadow: $v $b $s $c;
  box-shadow: $v $b $s $c;
}
