.icon-badge {
  @extend %black-text;

  display: inline-block;
  padding: 0;
  width: auto;

  a {
    display: block;
    font-size: 4.2em;
    height: 96px;
    line-height: 96px;
    margin: 8px 0;
    text-align: center;
    width: 96px;

    & > * {
      max-height: 96px;
      vertical-align: middle;
      width: 100%;
    }
  }

  .hover {
    &:hover {
      @include border-radius(50%);

      background-color: rgba(232, 232, 232, 1);
    }
  }

  @media screen and (max-width: 707px) { margin: 0 16px; }
}
