.about-me,
.education,
.employment,
.experience,
.interests {
  margin: 16px auto;
  width: 95%;
}

.entry {
  margin: 32px auto;
  text-align: center;

  h3,
  h4 { display: inline-block; }

  h4 { float: right;}

  img {
    vertical-align: top;
    width: 30%;
  }

  div {
    display: inline-block;
    margin-left: 48px;
    text-align: left;
    width: 60%;
  }

  p {
    margin: 8px auto;
    text-align: justify;
  }

  ul {
    font-size: 14px;
    padding-left: 32px;
  }

  @media screen and (max-width: 750px) {

    h3,
    h4 { display: block; }

    h4 { float: none;}

    img {
      display: block;
      margin: 16px auto;
      width: 75%;
    }

    div {
      display: block;
      margin: 16px auto;
      width: 95%;
    }
  }

  @media screen and (min-width: 1750px) {
    img { width: 25%; }
    div { width: 70%; }
  }
}

.interest,
.skill {
  display: inline-block;
  margin-bottom: 16px;
  text-align: center;
  vertical-align: top;

  a { margin: 8px auto; }

  p {
    margin: 8px auto;
    text-align: left;
  }

  .icon-badge {
    margin: 0;
    width: 90%;
  }

  @media screen and (max-width: 449px) { display: block; }

  @media screen and (min-width: 450px) and (max-width: 799px) {
    margin-left: 16px;
    width: 45%;
  }

  @media screen and (min-width: 800px) and (max-width: 1279px) {
    margin-left: 16px;
    width: 30%;
  }

  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    margin-left: 48px;
    width: 20%;
  }

  @media screen and (min-width: 1440px) and (max-width: 1539px) {
    margin-left: 56px;
    width: 15%;
  }

  @media screen and (min-width: 1540px) {
    margin-left: 64px;
    width: 15%;
  }
}
