.header {
  @extend %white-text;
  @include box-shadow(0, 5px, 10px, rgba(0, 0, 0, .15));

  background-color: $accent-color-1;
  width: 100%;
}

.triangilify {
  background-image: url('images/trianglify.svg');
  height: 375px;
  text-align: center;
  text-shadow: 0 5px 10px rgba(0, 0, 0, .15);
  width: 100%;

  div {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 49%;

    div {
      height: auto;
      margin: 105px 0;
      width: auto;
    }
  }

  .oscar {
    @include box-shadow(0, 5px, 10px, rgba(0, 0, 0, .15));
    @include border-radius(50%);

    background-image: url('images/oscar.jpg');
    background-size: 100%;
    border: 1px solid $white;
    height: 325px;
    margin: 24px 0;
    width: 325px;
  }

  h1,
  h2,
  h3 {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }

  h1 { font-size: 3em; }

  h2 { font-size: 2.5em; }

  h3 { font-size: 2em; }

  @media screen and (max-width: 750px) {
    div div { margin: 136px 0; }

    .oscar {
      height: 275px;
      margin: 49px 0;
      width: 275px;
    }

    h1 { font-size: 2.5em; }

    h2 { font-size: 2em; }

    h3 { font-size: 1.5em; }
  }

  @media screen and (max-width: 595px) {
    div {
      height: auto;
      width: 50%;

      div {
        height: auto;
        margin: 0;
      }
    }

    .oscar {
      height: 235px;
      margin: 10px 0;
      width: 235px;
    }

    h1 { font-size: 2em; }

    h2 { font-size: 1.5em; }

    h3 { font-size: 1.17em; }
  }

  @media screen and (max-width: 420px) {
    div {
      width: auto;

      div { width: auto; }
    }
  }
}

.logo {
  font-size: 1.2em;
  left: 15px;
  line-height: 50px;
  position: absolute;
}

nav {
  @extend %primary-colors-1;

  font-size: .875em;
  height: 50px;
  line-height: 50px;
  margin: auto;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  div {
    height: 100%;
    position: relative;
    width: 100%;

    div {
      position: absolute;
      right: 15px;
      width: auto;
    }
  }

  a {
    color: $white;
    display: inline-block;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    text-decoration: none;

    &:active { background-color: $accent-color-2; }

    &:hover { background-color: $accent-color-1; }
  }

  .active {
    span {
      background-color: $accent-color-1;
      bottom: 0;
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}
