.xp-bar {
  @extend %white-text;

  @include border-radius(2px);

  background-color: $light-grey;
  border: 4px solid $primary-color-1;
  display: block;
  height: 28px;
  margin: 8px auto;
  position: relative;

  & > * { height: 28px; }

  div { background-color: $accent-color-1; }

  span {
    left: 0;
    line-height: 28px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }
}
