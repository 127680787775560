// scss-lint:disable ColorVariable

@import 'var';
@import 'mix';
@import 'header';
@import 'paper-card';
@import 'icon-badge';
@import 'xp-bar';
@import 'index';
@import 'about-me';
@import 'footer';

html,
body {
  background-color: $light-grey;
  height: 100%;
  margin: 0;
  min-height: 288px;
  min-width: 300px;
  padding: 0;
}

body {
  font-family: $font-stack;
  & > .ember-view { height: 100%; }
}

.wrapper {
  margin: 0 auto -52px;
  min-height: 100%;
}

.main {
  margin: 32px 0;
  width: 100%;
}

.push { height: 52px; }

.footer { height: 20px; }

.icon-set {
  text-align: center;

  .ember-view { display: inline-block; }
}

.op-envelope,
.op-github,
.op-steam { color: $black; }

.op-instagram { color: rgba(81, 57, 52, 1); }

.op-tumblr { color: rgba(53, 70, 92, 1); }

.op-twitter { color: rgba(85, 172, 238, 1); }

.op-linkedin { color: rgba(19, 120, 179, 1); }

.op-heart { color: $accent-color-1; }

@media screen and (max-width: 432px) {
  .wrapper { margin: 0 auto -72px; }

  .push { height: 72px; }

  .footer { height: 40px; }
}
