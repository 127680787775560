// scss-lint:disable ColorVariable

// fonts
@font-face {
  font-family: 'Avenir-Medium';
  src: url('/assets/fonts/Avenir-Medium.woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/assets/fonts/HelveticaNeue.woff');
}

@font-face {
  font-family: 'Helvetica';
  src: url('/assets/fonts/Helvetica.woff');
}

$font-stack: 'Avenir-Medium', 'Helvetica Neue', Arial, Helvetica, sans-serif;

// icon families
.op { font-family: 'Fonticons'; }
.devicon { font-family: devicon; }

// color palette
$black: #000;
$white: #fff;
$light-grey: #fafafa;
$dark-grey: #424242;
$primary-color-1: #3f51b5;
$primary-color-2: #303f9f;
$primary-color-3: #c5cae9;
$accent-color-1: #ff8a80;
$accent-color-2: #ff1744;

// global text styling
%black-text {
  color: $black;

  .primary-text { color: rgba(0, 0, 0, .87); }

  .secondary-text,
  .icon-text { color: rgba(0, 0, 0, .54); }

  .hint-text,
  .disabled-text { color: rgba(0, 0, 0, .26); }

  .divider-text { color: rgba(0, 0, 0, .12); }
}

%white-text {
  color: $white;

  .primary-text { color: rgba(255, 255, 255, 1); }

  .secondary-text,
  .icon-text { color: rgba(255, 255, 255, .7); }

  .hint-text,
  .disabled-text { color: rgba(255, 255, 255, .3); }

  .divider-text { color: rgba(255, 255, 255, .12); }
}

%primary-colors-1 {
  @extend %white-text;

  background-color: $primary-color-1;

  .accent { color: $accent-color-1; }
}

%primary-colors-2 {
  @extend %white-text;

  background-color: $primary-color-2;

  .accent { color: $accent-color-2; }
}

%primary-colors-3 {
  @extend %black-text;

  background-color: $primary-color-3;

  .accent { color: $accent-color-1; }
}

%accent-colors-1 {
  @extend %white-text;

  background-color: $accent-color-1;
}

%accent-colors-2 {
  @extend %white-text;

  background-color: $accent-color-2;
}

.black-text { @extend %black-text; }

.white-text { @extend %white-text; }

.primary-colors-1 { @extend %primary-colors-1; }

.primary-colors-2 { @extend %primary-colors-2; }

.primary-colors-3 { @extend %primary-colors-3; }

.accent-colors-1 { @extend %accent-colors-1; }

.accent-colors-2 { @extend %accent-colors-2; }
